import React, { useEffect, useContext, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Code } from 'react-feather';
import  Directory from './Directory';
import { AppContext } from '../../context/AppProvider';


const DIRECTORY_TYPE = 'dir';
const FILE_TYPE = 'file'

const RenderFileList = (data: any, projectName: string, isMobile = false, sub = false) => {
    const appContext = useContext(AppContext);
    
    if(!data) {
        return null;
    }

    const truncate = (text: string) => {
        if(!text) {
            return text;
        } 

        if(text.length < 15) {
            return text;
        }

        return `${text.substring(0, 16)}...`
    }

    
    
    return (
        <>
            <ListGroup className={sub ? 'subfile': ''}>
                { projectName && <h5 className="project-name"><strong>{projectName}</strong></h5> }
                {
                    data.map((item: any) => {
                        if(item.type === FILE_TYPE) {
                            // Component for code
                            return (
                                <div key={item.sha}>
                                    <OverlayTrigger
                                        key={'bottom'}
                                        placement={'bottom'}
                                        overlay={
                                        <Tooltip id={`tooltip-${item.name}`}>
                                            {item.name}
                                        </Tooltip>
                                        }
                                        >
                                        <ListGroup.Item  
                                            style={{
                                                cursor: 'pointer',
                                                backgroundColor: item._links.self === appContext.currentFile ? '#6c757d' : '#272822',
                                                borderRadius: item._links.self === appContext.currentFile ? '10px': 0
                                            }}
                                            
                                            as="a"
                                            onClick={() => { 
                                                if(appContext.getFile) {
                                                    appContext.setCurrentPath(item.path.split("/"));
                                                    appContext.getFile(item._links.self);
                                                    appContext.setDownloadUrl(item.download_url);
                                                    if(isMobile) {
                                                        appContext.toggleFileSystem();
                                                    }
                                                    //appContext.toggleFileSystem();
                                                    
                                                }
                                            }} ><Code style={{
                                                width: '14px',
                                                height: '14px',
                                                cursor: 'pointer'
                                            }}/>  {truncate(item.name)}</ListGroup.Item>
                                    </OverlayTrigger>
                                </div>
                            )
                        }

                        if(item.type === DIRECTORY_TYPE) {
                            // Component for folder
                            return (
                                <Directory
                                    key={item.sha}
                                    name={item.name}
                                    data={item}
                                    renderFileList={RenderFileList}
                                />
                            )
                        }

                        return null;
                    })
                }
            </ListGroup>
        </>
    )
}

const FileList = ({ isMobile }: { isMobile?: boolean}) => {
    const appContext = useContext(AppContext);
    const [ data, setData ] = useState([]);

    useEffect(() => {
        setData(appContext.data);
    }, [appContext.data, appContext.url])

    return (
        <>
            {
                RenderFileList(data, appContext.repo ? appContext.repo.name : '', isMobile)
            }
        </>
    )
}

export default FileList;