import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Draggable from 'react-draggable';
import { useMediaQuery } from '@react-hook/media-query';
import { AppContext } from '../../../context/AppProvider';
import { Edit } from 'react-feather';
import RichTextEditor from 'react-rte';


const MyNote = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const { title, description, setShowNote } = useContext(AppContext);
    const matches = useMediaQuery('only screen and (max-width: 768px)');

    useEffect(() => {
        if(title && description) {
            setIsOpen(true);
        }
    }, [title, description, isOpen])

    useEffect(() => {
        setIsOpen(isOpen);
    }, [isOpen])
    

    if(matches) {
        return null;
    }
    
    // const getDate = (date: any) => {
    //     try {
    //         return new Date(parseInt(date))
    //     } catch(error) {
    //         return 'Just now'
    //     }
    // }
    // console.log("update date", lastUpdate);
    return (
        <>
            {
                isOpen && title && description && (
                    <Draggable handle=".view-note">
                        <Card className="view-note" style={{ width: '18rem', cursor: 'move' }}>
                            <Card.Body>
                                <Card.Title style={{color: "#fff"}}>{title}</Card.Title>
                                {/* <small style={{color: '#fff'}}>{getDate(lastUpdate)}</small> */}
                                <RichTextEditor
                                    readOnly={true}
                                    value={RichTextEditor.createValueFromString(description, 'html')}
                                    
                                />
                                <br />
                                <br />
                                <br />
                                <Button 
                                    variant="primary"
                                    style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        left: '10px'
                                    }}
                                    onClick={() => setShowNote(true) }
                                >
                                    <Edit size={16} /> {' '}
                                    Edit
                                </Button>
                                <Button 
                                    onClick={() => setIsOpen(false)}
                                    variant="dark" 
                                    style={{
                                        position: 'absolute',
                                        bottom: '10px',
                                        right: '10px'
                                    }}
                                >
                                    Close
                                </Button>
                               
                            </Card.Body>
                        </Card>
                    </Draggable>
                )
            }
        </>
    )
}

export default MyNote;
