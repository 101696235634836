import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { API_ROUTES } from '../types';


type AppContextProps = {
    url: string, 
    data: any,
    friendlyUrl: string,
    setFriendlyUrl: (url: string | undefined) => void,
    handleSetUrl: (url: string | undefined) => any,
    getFile: (url: string) => any, 
    fileContent: any, 
    downloadUrl: string | null,
    setDownloadUrl: any,
    repo: any,
    clearError: () => any, 
    error: any,
    token: String | null,
    loading: boolean,
    getMyRepo: () => any,
    addMyRepo: (title: string, description: string, repo_url: string, url: string) => any,
    deleteMyRepo: (repo_url: string) => void,
    showFileSystem: any,
    toggleFileSystem: any,
    setShowFileSystem: any,
    currentFile: string,
    currentPath: string, 
    setCurrentPath: any,
    title: any,
    setTitle: any,
    setDescription: any,
    description: any,
    showNote: any, 
    setShowNote: any,
    lastUpdate: any,
    deleteMyNote: any,
    setLastUpdate: any
}

export const AppContext = createContext<Partial<AppContextProps>>({});

const HTTP_SUCCESS = 200;

const AppContextProvider = ({ children }: any) => {
    const [ url, setUrl ]: [ any, any ] = useState(localStorage.getItem('url'));
    const [ baseUrl, setBaseUrl ]: [any, any ] = useState(localStorage.getItem('baseUrl'));
    const [ friendlyUrl, setFriendlyUrl ]:[any, any] = useState(''); // Url without the API manipulation
    const [ data, setData ]: [ any, any ] = useState(null);
    const [ repo, setRepo ]: [ any, any ] = useState(null);
    const [ title, setTitle ] = useState('');     
    const [ description, setDescription ] = useState('');
    const [ lastUpdate , setLastUpdate ] = useState('');
    const [ fileContent, setFileContent ] : [ any, any ] = useState(null);
    const [ downloadUrl, setDownloadUrl ] : [ any, any ] = useState(null);
    const [ error, setError ] : [ any, any ] = useState(null);
    const [ loading, setLoading ]: [any, any] = useState(false);
    const { token } = useAuth();
    const [ showFileSystem, setShowFileSystem ] = useState(false);
    const [ showNote, setShowNote ] = useState(false);
    const [ currentFile, setCurrentFile ] = useState('');
    const [ currentPath, setCurrentPath ] = useState(''); 

    const toggleFileSystem = () => {
        setShowFileSystem(!showFileSystem);
    }

    const clearError = () => {
        setError(null);
    }

    // TODO: Make this mad comprehensive for different services
    const handleSetUrl = (url: string | undefined) => {
        console.log("Url", url);
        if(!url) {
            return false;
        }
        if(!url.includes("github.com")) {
            setError("Enter a valid URL");
            return false
        }

        setFriendlyUrl(url); 
        const api_url = url.replace('github.com', 'api.github.com/repos');
        setBaseUrl(api_url);
        setUrl(`${api_url}/contents`);
        localStorage.setItem('url', `${api_url}/contents`);
        localStorage.setItem('baseUrl',  api_url);
    }

    const getRepoInformation = (url: string) => {
        axios.get(baseUrl, {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((result: any) => {
            (result.status === HTTP_SUCCESS ) && setRepo(result.data);
        }).catch(() => {
            setError('Failed to get repo information');
            localStorage.removeItem('url')
        })
    }

    const getFile = (fileUrl: string) => {
        setLoading(true);
        setCurrentFile(fileUrl);
        axios.get(fileUrl, {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((result) => {
            (result.status === HTTP_SUCCESS) && setFileContent(result.data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            setError('Failed to get file.')
        })
    }

    const getMyRepo = async () => {
        const token = localStorage.getItem('token');
        if(!token) {
            return null;
        }

        const { data } = await axios.get(API_ROUTES.REPO_URL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        
        return data.data;
    }

    const addMyRepo = async (title: string, description: string, repo_url: string, url: string) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        if(!token) {
            return null;
        }
        try {
            await axios.post(API_ROUTES.REPO_URL, {
                title, 
                description, 
                repo_url,
                url
            }, {
                headers: {
                    "Content-type": "Application/json",
                    'Authorization': `Bearer ${token}`
                }
            })

            setLoading(false);

            setError(`Saved ${title} successfully!`);
        } catch (error) {
            setError("Something went wrong saving repo");
            setLoading(false);
        }
        

        return data;
    }

    const deleteMyRepo = async (repo_url: string) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        if(!token) {
            return null;
        }

        try {
            await axios.delete(`${API_ROUTES.REPO_URL}/${repo_url}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

            setLoading(false);
            setError(`Deleted Repo!`);

        } catch(error) {
            setLoading(false);
            setError("Something went wrong delete repo");
        }
    }

    const deleteMyNote = async (url: string, file: string) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        if(!token) {
            return null;
        }

        try {
            await axios.delete(`${API_ROUTES.BACKEND_NOTE}/${encodeURIComponent(url)}/${file}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

            setLoading(false);
            setError(`Deleted Note!`);

        } catch(error) {
            setLoading(false);
            setError("Something went wrong delete repo");
        }
    }

    const saveMyRepo = () => {
        const token = localStorage.getItem('token');

        if(!token) {
            setError("Something went wrong - Authenticating user")
        }

        try {
            addMyRepo(repo.full_name, repo.description, repo.git_url, repo.html_url);

        } catch(error) {
            setError("Something went wrong saving the repo")
        }
    }

    // When user hits the s?= automatically save repo information to the backend and issue message that it has been saved
    useEffect(() => {
        if(!repo) {
            return ;
        }

        const current_url = new URL(window.location.href);
        const s = current_url.searchParams.get("s");

        if(s) {
            handleSetUrl(s);
        }

        if(s && s.trim() === repo.html_url.trim()) {
            saveMyRepo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ repo ]);

    useEffect(() => {
        const current_url = new URL(window.location.href);
        const q = friendlyUrl ? friendlyUrl: current_url.searchParams.get("q");
        
        if(q) {
            handleSetUrl(q);
        }
        
        if(!url) {
            return;
        }
        
        getRepoInformation(baseUrl);
        
        axios.get(url, {
            headers: {
                'Authorization': `JWT ${token}`
            }
        }).then((result: any) => { 
            (result.status === HTTP_SUCCESS) ? setData(result.data) : setData([]); 
            if(result.status === HTTP_SUCCESS) {
                const readme = result.data.find((item: any) => item.name.toLowerCase() === "readme.md" || item.name.toLowerCase() === "readme.rst");
                if(readme) {
                    getFile(readme.url);
                    setCurrentPath(readme.path)
                    
                }
                
            }
        }).catch(() => setError('Failed to get URL'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ url, baseUrl ]);

    return (
        <AppContext.Provider
            value={{
                url, 
                handleSetUrl,
                data,
                getFile,
                fileContent,
                setFriendlyUrl,
                downloadUrl,
                friendlyUrl,
                setDownloadUrl,
                repo, 
                token,
                clearError,
                error,
                getMyRepo,
                addMyRepo,
                deleteMyRepo,
                loading,
                showFileSystem,
                toggleFileSystem,
                currentFile,
                currentPath, 
                title,
                setTitle,
                setDescription,
                description,
                setCurrentPath,
                setShowFileSystem,
                showNote, 
                setShowNote,
                deleteMyNote,
                lastUpdate, 
                setLastUpdate
            }}
        >
            {children}
        </AppContext.Provider>
    )

}

export default AppContextProvider;