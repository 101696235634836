import React from 'react';
import './App.css';

import AppContext from './context/AppProvider';
import { AuthProvider } from './context/AuthContext';
import Navigation from './components/navigation';
import CodeScreen from './layout/CodeScreen';
import Error from './components/errors';

const App = () => {

  return (
    <AuthProvider>
      <AppContext>
        <Navigation />
        <CodeScreen />
        <Error />
      </AppContext>
    </AuthProvider>
  );
}

export default App;
