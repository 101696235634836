import React, { useState, useEffect, useContext } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Draggable from 'react-draggable';
import { FileText } from 'react-feather';
import axios from 'axios';
import { API_ROUTES } from '../../types';
import { AppContext } from '../../context/AppProvider';


const ViewAllNotes = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ notes, setNotes ] = useState([]);
    const [ loading,  ] = useState(false);
    const [ indexBeingDeleted, setIndexBeingDeleted ]:[any, any] = useState(null);
    const { deleteMyNote, handleSetUrl, loading: isDeleting } = useContext(AppContext);

    useEffect(() => {
        // Get all notes from users
        const token = localStorage.getItem('token');
        if(token) {
            axios.get(`${API_ROUTES.BACKEND_NOTES}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then((result) => {
            
                (result.status === 200) && setNotes(result.data.data); 
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, deleteMyNote ])

  
    const isBeingDeleted = (index: number) => {
        if(index === indexBeingDeleted && isDeleting) {
            return true;
        } 

        return false;
    }

    return (
        <>
            {
               isOpen && (
                <Draggable>
                    <Modal show={isOpen} onHide={() => setIsOpen(!isOpen)}>
                        <Modal.Header closeButton>
                            <Modal.Title>My Notes</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>  
                            {
                                loading && (
                                    <Spinner  animation="border" variant="secondary" />
                                )
                            }
                            {
                                notes && !loading && notes.length === 0 &&  (
                                    <p>No Notes Found</p>
                                )
                            }
                            {
                                notes && notes.map((item: any, i: number) => (
                                    <>
                                        { console.log(item)}
                                        <ListGroup.Item style={{cursor: 'pointer', background: '#272822', color: '#eee'}}  variant="dark" onClick={() => {
                                            // window.open(`${window.location.origin}?q=${item.url}`)
                                            // console.log("URL being handled", item.url);
                                            
                                            handleSetUrl && handleSetUrl(item.url);
                                            setIsOpen(false);
                                        }}>
                                            
                                            <Row>
                                                <Col ><strong>({item.url})</strong> <br /> {item && item.title} <small style={{float: 'right', marginTop: '3px'}}>{new Date(parseInt(item.created)).toDateString()}</small> 
                                                {/* <hr style={{background: '#c6c8ca', clear: 'both' }} />  */}
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                            <Col ><Button onClick={(e: any) => {
                                                e.stopPropagation();
                                                setIndexBeingDeleted(i);
                                                deleteMyNote && deleteMyNote(item.url, item.file);
                                                //deleteMyNote && deleteMyNote(encodeURIComponent(item.url), );
                                                // setIndexBeingDeleted(null);
                                            }} style={{float: 'right'}} variant="danger">
                                                { isBeingDeleted(i) && <Spinner animation="grow" variant="light" size="sm" /> }
                                                {' '}Delete</Button></Col>
                                            </Row>
                                        </ListGroup.Item>
                                        <br />
                                    </>
                                ))
                            }
                        </Modal.Body>
                    </Modal>
                </Draggable>
               )     
            }
            <NavDropdown.Item onClick={() => setIsOpen(true)}><FileText size="18" />{' '}My Notes</NavDropdown.Item>
        </>
    )
}

export default ViewAllNotes;