import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Save } from 'react-feather';
import Spinner from 'react-bootstrap/Spinner';
import { AppContext } from '../../../context/AppProvider';
import { useAuth } from '../../../context/AuthContext';
import {useMediaQuery } from '@react-hook/media-query';


const SaveRepoButton = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const { repo, addMyRepo, loading } = useContext(AppContext)
    const [ title, setTitle ] = useState(repo ? repo.full_name : ''); 
    const [ description, setDescripton ] = useState(repo ? repo.description : '');
    const [ url, setUrl ] = useState(repo ? repo.html_url : '');
    const [ repoUrl, setRepoUrl ] = useState(repo ? repo.git_url : ''); 
    const { isAuthenticated } = useAuth();
    const matches = useMediaQuery('only screen and (max-width: 768px)');
   
    useEffect(() => {
        if(repo) {
            setTitle(repo.full_name);
            setDescripton(repo.description);
            setRepoUrl(repo.git_url);
            setUrl(repo.html_url);
        }
    }, [repo])
    
    if(!isAuthenticated) {
        return null;
    }
    
    return (
        <>
          <Modal show={isOpen} onHide={() => setIsOpen(!isOpen)}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Repository</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formRepo">
                            <Form.Label>Title</Form.Label>
                            <Form.Control onChange={(e: any) => setTitle(e.target.value)} value={title} type="text" placeholder="Title" />
                        </Form.Group>
                        <Form.Group controlId="formRepo">
                            <Form.Label>Rep URL</Form.Label>
                            <Form.Control  onChange={(e: any) => setRepoUrl(e.target.value)} value={repoUrl} type="text" placeholder="Repo URL" readOnly />
                        </Form.Group>
                        <Form.Group controlId="repoDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control  onChange={(e: any) => setDescripton(e.target.value)} value={description} as="textarea" rows={3} />
                        </Form.Group>
                        <Button disabled={loading} variant="primary" onClick={() => {
                            addMyRepo && addMyRepo(title, description, repoUrl, url);
                            setIsOpen(false);
                        }}>
                            { loading && <Spinner animation="grow" variant="light" size="sm" />}{' '} Save Repository
                        </Button>
                        {' '}
                        <Button onClick={() => setIsOpen(false)} variant="dark" >
                            Cancel
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Button 
                variant="dark" 
                className="edit-button"
                onClick={() => setIsOpen(true)}
            >
                <Save />{' '}{!matches ? 'Save Repo': null }</Button>  
        </>
    )

}


export default SaveRepoButton;