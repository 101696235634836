import React, { useState, useContext } from 'react';
import axios from 'axios';
import ListGroup from 'react-bootstrap/ListGroup';
import { FolderPlus, FolderMinus } from 'react-feather';
import { AppContext } from '../../context/AppProvider';
import {useMediaQuery } from '@react-hook/media-query';

interface IDirectory {
    data: any,
    name: string, 
    renderFileList: (data: any, projectName: string, isMobile: boolean, sub: boolean) => any
}

const Folder = (isExpaned = false) => (isExpaned ? <FolderMinus /> : <FolderPlus /> )

const Directory = ({ name, data , renderFileList }: IDirectory) => {
    const [ children,  setChildren ] = useState([]);
    const [ isExpaned, setIsExpaned ] = useState(false);
    const { currentPath } = useContext(AppContext);
    const matches = useMediaQuery('only screen and (max-width: 768px)');
    
    const getChildren = () => {
        setIsExpaned(!isExpaned);
        axios.get(data._links.self).then((result) => setChildren(result.data))
    }
    // Todo: use data.path not name because it will produce false postives for folders whos names are the same which is highly likely

    return (
        <>
            <ListGroup.Item style={{
                cursor: 'pointer', 
                backgroundColor: '#272822',
                color: currentPath?.includes(data.name) ? 'rgb(108, 117, 125)' : '#fff'

            }} onClick={() => getChildren() }>{Folder(isExpaned)} {name}</ListGroup.Item>
            {(isExpaned && children.length > 0 ) && renderFileList(children, '', matches, true)}
        </>
    )
}


export default Directory;