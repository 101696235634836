import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import Card from 'react-bootstrap/Card'
import { Play } from 'react-feather';
import { CodeEditor } from '../../components/editor';
import { FileList } from '../../components/editor';
import { CodeQualityButton, CodeRunButton, SaveRepoButton, ViewNote, MyNote, Share } from './options';
import { useMediaQuery } from '@react-hook/media-query';
import './style.css';
import { AppContext } from '../../context/AppProvider';
import { useSwipeable } from 'react-swipeable';
import { motion } from 'framer-motion';

const ENTER_KEY_CODE = 13;

const CodeScreen = () => {
    const { handleSetUrl,  friendlyUrl, setFriendlyUrl, showFileSystem , setShowFileSystem} = useContext(AppContext);
    const matches = useMediaQuery('only screen and (max-width: 768px)');
    const desktop = useMediaQuery('only screen and (max-width: 1366px)');

    const handlers = useSwipeable({
        onSwipedRight: (event) => {
            if(!matches) {
                return false;
            } 
            if(event.initial[0] < 35) {
                setShowFileSystem(true);
            }
         },
        onSwipedLeft: () => {  
            if(!matches) {
                return false;
            } 
            setShowFileSystem(false) 
        }
    });

    if(showFileSystem) {
        return (
            <Container fluid={true}>
                <motion.div {...handlers} 
                    initial={{ x: -10 }}
                    animate={{ x: -1 }}
                >
                    <Row>
                        {
                            matches && (
                                <Col className="panel file scroll-bar" xs="12"> 
                                    <FileList isMobile={matches} />
                                </Col>
                            )
                        }
                    </Row>
                </motion.div>
            </Container>
        )
    }

    return(
        <Container fluid={true} >
            <div {...handlers}>
                <Row >
                    {
                        !matches && (
                            <Col className="panel file scroll-bar" xs={desktop ? '3': '2'}> 
                                <FileList isMobile={matches} />
                                
                                {/* <Card> 
                                    <Card.Body>
                                        <Card.Title>Your Ad Here</Card.Title>
                                        <Card.Text>
                                            <code>Your super cool ad could appear here.</code>
                                        </Card.Text>
                                    </Card.Body>
                                </Card> */}
                            </Col>
                        )
                    }
                    <Col className="panel code" md={desktop ? '9': '10'} xs={12}  > 
                        <Col className="control-panel">
                            <Form inline> 
                                <Form.Control 
                                    value={friendlyUrl}
                                    onChange={(e: any) => setFriendlyUrl && setFriendlyUrl(e.target.value) }
                                    onKeyDown={(e: any) => {
                                        if(e.keyCode === ENTER_KEY_CODE) {
                                            e.preventDefault();
                                            setFriendlyUrl && setFriendlyUrl(friendlyUrl);
                                            handleSetUrl && handleSetUrl(friendlyUrl);
                                        }   
                                    }}
                                    
                                    type="url" 
                                    placeholder="Paste Github URL" />
                                <Button 
                                    onClick={() => handleSetUrl && handleSetUrl(friendlyUrl)}
                                    variant="dark" 
                                    className="edit-button"><Play /> {!matches ? 'Pull Code': null }</Button> 
                                {
                                    !matches && (
                                        <>
                                            {/* <Button variant="dark" className="edit-button" onClick={
                                                () => { 
                                                    if(downloadUrl) {
                                                        window.open(downloadUrl);    
                                                    }
                                                }
                                            }><DownloadCloud /> Download</Button>  */}
                                            <CodeQualityButton />
                                            <CodeRunButton />
                                            <SaveRepoButton />
                                            <ViewNote />
                                            <Share />
                                        </>
                                    )
                                }
                            </Form>
                        </Col>
                        <CodeEditor />
                        {/* <br />
                        <br />
                        <br />
                        <br />
                        <br /> */}
                    </Col>
                    <>
                    {
                        matches && (
                            <div style={{
                                position: 'fixed',
                                bottom: 10,
                                zIndex: 100,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                {/* <Button variant="dark" className="edit-button" onClick={
                                    () => { 
                                        if(downloadUrl) {
                                            window.open(downloadUrl);    
                                        }
                                    }
                                }><DownloadCloud /> {!matches ? 'Download': null }</Button>  */}
                                <CodeQualityButton />
                                <CodeRunButton />
                                <SaveRepoButton />
                                <ViewNote />
                                <Share />
                            </div>
                        )
                    }
                    </>
                </Row>
            </div>
            <MyNote />
        </Container>
    )
};

export default CodeScreen;
