import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Command } from 'react-feather';
import {useMediaQuery } from '@react-hook/media-query';

const CodeQualityButton = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const matches = useMediaQuery('only screen and (max-width: 768px)');

    return (
        <>
            <Modal show={isOpen} onHide={() => setIsOpen(!isOpen)}>
                <Modal.Header closeButton>
                    <Modal.Title>Code Quality</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sorry <code>Code Quality Check</code> is currently unavailable</Modal.Body>
            </Modal>
            <Button 
                variant="dark" 
                className="edit-button"
                onClick={() => setIsOpen(true)}
            >
                <Command /> {!matches ? 'Code Quality': null }</Button>   
        </>
    )
}

export default CodeQualityButton;