import React, { useState, useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import Alert from 'react-bootstrap/Alert';

// make variant a enum of values based on 
const Error = ({ variant = 'danger' }: any) => {
    const appContext = useContext(AppContext);
    const [ isOpen, setIsOpen ]: [ any, any ] = useState(true);
    
    if(!appContext.error) {
        return null;
    }

    return (
        <Alert 
            className="error-alert" 
            show={isOpen} dismissible={true}  
            variant={variant} 
            onClose={() => setIsOpen(false)}
        >
            <p>{appContext.error}</p>
        </Alert>
    )
}

export default Error;