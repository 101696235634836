import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Share2 } from 'react-feather';
import { AppContext } from '../../../context/AppProvider';
import {useMediaQuery } from '@react-hook/media-query';

import {
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    
    WhatsappIcon,
  } from "react-share";


const Share = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const { repo } = useContext(AppContext)
    const matches = useMediaQuery('only screen and (max-width: 768px)');
    

    return (
        <>
          <Modal show={isOpen} onHide={() => setIsOpen(!isOpen)}>
                <Modal.Header closeButton>
                    <Modal.Title>Share This Repo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        
                        <WhatsappShareButton url={repo ? `https://www.app.perusecode.com?q=${repo.html_url}` : `https://www.appperusecode.com` }  >
                            <WhatsappIcon size={32} round={true} path="" />
                        </WhatsappShareButton>
                        {' '}
                        <TwitterShareButton url={repo ? `https://www.app.perusecode.com?q=${repo.html_url}` : `https://www.appperusecode.com` }>
                            <TwitterIcon size={32} round={true} path="" />
                        </TwitterShareButton>
                        {' '}
                        <FacebookShareButton url={repo ? `https://www.app.perusecode.com?q=${repo.html_url}` : `https://www.appperusecode.com` }>
                            <FacebookIcon size={32} round={true} path="" />
                        </FacebookShareButton>
                        {' '}
                        <TelegramShareButton url={repo ? `https://www.app.perusecode.com?q=${repo.html_url}` : `https://www.appperusecode.com` }>
                            <TelegramIcon size={32} round={true} path="" />
                        </TelegramShareButton>
                        {' '}
                        <RedditShareButton url={repo ? `https://www.app.perusecode.com?q=${repo.html_url}` : `https://www.appperusecode.com` }>
                            <RedditIcon size={32} round={true} path="" />
                        </RedditShareButton>
                        {/* <TwitterIcon size={32} round={true} /> */}
                        {/* <Form.Group controlId="formRepo">
                            <Form.Label>Title</Form.Label>
                            <Form.Control onChange={(e: any) => setTitle(e.target.value)} value={title} type="text" placeholder="Title" />
                        </Form.Group>
                        <Form.Group controlId="formRepo">
                            <Form.Label>Rep URL</Form.Label>
                            <Form.Control  onChange={(e: any) => setRepoUrl(e.target.value)} value={repoUrl} type="text" placeholder="Repo URL" readOnly />
                        </Form.Group>
                        <Form.Group controlId="repoDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control  onChange={(e: any) => setDescripton(e.target.value)} value={description} as="textarea" rows={3} />
                        </Form.Group> */}
                        {/* <Button disabled={loading} variant="primary" onClick={() => {
                            // addMyRepo && addMyRepo(title, description, repoUrl, url);

                            setIsOpen(false);
                        }}>
                            { loading && <Spinner animation="grow" variant="light" size="sm" />}{' '} Save Repository
                        </Button> */}
                        <br />
                        <br />
                        {' '}
                        <Button onClick={() => setIsOpen(false)} variant="dark" >
                            Close
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Button 
                variant="dark" 
                className="edit-button"
                onClick={() => setIsOpen(true)}
            >
                <Share2 />{' '}{!matches ? 'Share': null }</Button>  
        </>
    )

}


export default Share;