import React, { useState, useEffect, useContext } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Code } from 'react-feather';
import { AppContext } from '../../context/AppProvider';


const ViewAllRepos = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ repo, setRepo ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ indexBeingDeleted, setIndexBeingDeleted ]:[any, any] = useState(null);
    const { getMyRepo, deleteMyRepo, handleSetUrl, loading: isDeleting } = useContext(AppContext);

    useEffect(() => {
        if(getMyRepo) {
            setLoading(true);
            getMyRepo().then((data: any) => {
                setRepo(data)
                setLoading(false);
            });
        }
    }, [getMyRepo])

    const isBeingDeleted = (index: number) => {
        if(index === indexBeingDeleted && isDeleting) {
            return true;
        } 

        return false;
    }

    return (
        <>
            {
               isOpen && (
                <Modal show={isOpen} onHide={() => setIsOpen(!isOpen)}>
                    <Modal.Header closeButton>
                        <Modal.Title>My Repositories</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>  
                        {
                            loading && (
                                <Spinner  animation="border" variant="secondary" />
                            )
                        }
                        {
                            repo && !loading && repo.length === 0 &&  (
                                <p>No Repos Found</p>
                            )
                        }
                        {
                            repo && repo.map((item: any, i: number) => (
                                <>
                                    <ListGroup.Item style={{cursor: 'pointer', background: '#272822', color: '#eee'}} variant="dark" onClick={() => {
                                        // window.open(`${window.location.origin}?q=${item.url}`)
                                        console.log("URL being handled", item.url);
                                        handleSetUrl && handleSetUrl(item.url);
                                        setIsOpen(false);
                                    }}>
                                        <Row>
                                            <Col >{item && item.title} - {item && item.description}</Col>
                                        </Row>
                                        

                                        <Row>
                                        <Col ><Button onClick={(e: any) => {
                                            e.stopPropagation();
                                            setIndexBeingDeleted(i);
                                            deleteMyRepo && deleteMyRepo(encodeURIComponent(item ? item.repo_url: null));
                                            // setIndexBeingDeleted(null);
                                        }} style={{float: 'right'}} variant="danger">
                                            { isBeingDeleted(i) && <Spinner animation="grow" variant="light" size="sm" /> }
                                            {' '}Delete</Button></Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <br />
                                </>
                            ))
                        }
                    </Modal.Body>
                </Modal>
               )     
            }
            <NavDropdown.Item onClick={() => setIsOpen(true)}><Code size="18" />{' '}My Repos</NavDropdown.Item>
        </>
    )
}

export default ViewAllRepos;