export const API_ROUTES = {
    AUTHENTICATION: 'https://github.com/login/oauth/authorize?client_id=7bba09ccd56a5f07ccff',
    GET_USER_INFORMATION: 'https://api.github.com/user', // we really don't need to proxy this
    GET_AUTHENTICATION_TOKEN: 'https://pgj1bt4l1h.execute-api.us-east-1.amazonaws.com/dev/v1/authenticate',
    REPO_URL: 'https://pgj1bt4l1h.execute-api.us-east-1.amazonaws.com/dev/v1/repo',
    BACKEND_NOTES: 'https://pgj1bt4l1h.execute-api.us-east-1.amazonaws.com/dev/v1/notes',
    BACKEND_GET_NOTE: 'https://pgj1bt4l1h.execute-api.us-east-1.amazonaws.com/dev/v1/note',
    BACKEND_NOTE: 'https://pgj1bt4l1h.execute-api.us-east-1.amazonaws.com/dev/v1/note'
}

export const ERRORS = {
    BAD_VERIFICATION_CODE: 'bad_verification_code'
}

