import React, { useContext } from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import ReactMarkdown from 'react-markdown'

import { AppContext } from '../../context/AppProvider';

const CodeEditor = () => {
    const appContext = useContext(AppContext);
    
    if(appContext.fileContent && (appContext.fileContent.name.toLowerCase().slice(-4) === ".gif")) {
        console.log("Image", appContext.fileContent.content);
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: '90%',
                alignItems: 'center'
            }}>
                <img src={`data:image/png;base64,${appContext.fileContent.content}`} alt={appContext.fileContent.name} />
            </div>
        )
    }

    if(appContext.fileContent && (appContext.fileContent.name.toLowerCase().slice(-4) === ".png")) {
        
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: '90%',
                alignItems: 'center'
            }}>
                <img src={`data:image/png;base64,${appContext.fileContent.content}`} alt={appContext.fileContent.name} />
            </div>
        )
    }

    if(appContext.fileContent && (appContext.fileContent.name.toLowerCase().slice(-4) === ".jpg")) {
        console.log("Image", appContext.fileContent.content);
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: '90%',
                alignItems: 'center'
            }}>
                <img src={`data:image/png;base64,${appContext.fileContent.content}`} alt={appContext.fileContent.name} />
            </div>
        )
    }

    if(appContext.fileContent && (appContext.fileContent.name.toLowerCase().slice(-4) === ".jpeg")) {
        console.log("Image", appContext.fileContent.content);
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: '90%',
                alignItems: 'center'
            }}>
                <img src={`data:image/png;base64,${appContext.fileContent.content}`} alt={appContext.fileContent.name} />
            </div>
        )
    }

    if(appContext.fileContent && (appContext.fileContent.name.toLowerCase().slice(-4) === ".svg")) {
        console.log("Image", appContext.fileContent.content);
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: '90%',
                alignItems: 'center'
            }}>
                <img src={`data:image/png;base64,${appContext.fileContent.content}`} alt={appContext.fileContent.name} />
            </div>
        )
    }

    if(appContext.fileContent && (appContext.fileContent.name.toLowerCase().slice(-3) === ".md")) {
        

        return (
            <div 
                style={{ 
                    color: '#fff', 
                    margin: '18px',
                    height: '100%',
                    overflowY: 'auto',
                    marginRight: 0
                }}>
                <ReactMarkdown 
                    allowDangerousHtml={true}
                >
                    
                    {atob(appContext.fileContent.content) + "<div><br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /><br /> <br /> <br /></div>" }
                        
                    
                </ReactMarkdown>
            </div>
            
        )
    }

    if(appContext.fileContent && (appContext.fileContent.name.toLowerCase().slice(-4) === ".rst")) {
        

        return (
            <div 
                style={{ 
                    color: '#fff', 
                    margin: '18px',
                    height: '100%',
                    overflowY: 'auto'
                }}>
                <ReactMarkdown allowDangerousHtml={true} >
                    {atob(appContext.fileContent.content) + "<div><br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /><br /> <br /> <br /></div>" }
                </ReactMarkdown>
            </div>
            
        )
    }


    return (
        <AceEditor
            className="code-editor"
            placeholder=""
            mode="python"
            theme="monokai"
            // onLoad={() => console.warn("Impmlement on load")}
            // onChange={() => console.warn("Implement on change")}
            fontSize={16}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={appContext.fileContent ? atob(appContext.fileContent.content) + "\n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n"  : ''}
            setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
            }}
        />
    )
}

export default CodeEditor;