import React, { useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Spinner from 'react-bootstrap/Spinner';
import { GitHub } from 'react-feather';
import { useAuth } from '../../context/AuthContext';
import ViewAllRepos from '../repos';
import ViewAllNotes from '../notes';
import { User, LogOut, AlignJustify } from 'react-feather';
import Logo  from '../../P.png';
import { API_ROUTES } from '../../types';
import {useMediaQuery } from '@react-hook/media-query';
import { AppContext } from '../../context/AppProvider';

const Navigation = () => {
    const { isAuthenticated, userInfo, logOut} = useAuth(); 
    const { toggleFileSystem, loading } = useContext(AppContext)
    const matches = useMediaQuery('only screen and (max-width: 768px)');
    
    if(matches) {
        return (
            <>
                <Navbar className="code-header" style={{justifyContent: 'space-between'}} >
                    <AlignJustify color="#eee" onClick={() => toggleFileSystem() } /> 
                
                    <Navbar.Brand href="#">
                        <img src={Logo} style={{height: '4vh', marginTop: '-8px', marginRight: '10px'}} alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="options" />
                    <Navbar.Collapse style={{ flexBasis: 'auto', flexGrow: 0 }}>
                        {
                            isAuthenticated ? (
                                <NavDropdown style={{backgroundColor: '#000', borderRadius: '10px'}} id="user_account" title={userInfo ? `Hi, ${userInfo.login}` : 'John Doe'}>
                                    <NavDropdown.Item href={userInfo ? userInfo.html_url : '#'} target="_blank" ><User size="18" />{' '}Profile</NavDropdown.Item>
                                    <ViewAllRepos />
                                    <ViewAllNotes />
                                    {/* <NavDropdown.Item><Settings size="18" />{' '} Prefrences</NavDropdown.Item> */}
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={() => logOut()}><LogOut size="18" />{' '}Logout</NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <Nav >
                                    <Nav.Link className="btn btn-secondary outline" type="button" href={API_ROUTES.AUTHENTICATION}><GitHub size="18" />{' '}Login via Github</Nav.Link>
                                </Nav>
                            )
                        }
                        
                    </Navbar.Collapse>
                </Navbar>
                {
                    loading && <Spinner 
                        style={{
                            position: 'fixed',
                            right: '46%',
                            top: '50%',
                            zIndex: 200,
                            backgroundColor: '#339966'
                        }}
                        variant="info"
                        animation="grow" />
                }
            </>
        )
    }

    return (
        <>
            <Navbar className="code-header" style={{justifyContent: 'space-between'}} >
                <Navbar.Brand href="#">
                    <img src={Logo} style={{height: '4vh', marginTop: '-8px', marginRight: '10px'}} alt="Logo" />
                    {' '}
                    Peruse Code
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="options" />
                <Navbar.Collapse style={{ flexBasis: 'auto', flexGrow: 0 }}>
                    {
                        isAuthenticated ? (
                            <NavDropdown style={{backgroundColor: '#000', borderRadius: '10px'}} id="user_account" title={userInfo ? `Hi, ${userInfo.login}` : 'John Doe'}>
                                <NavDropdown.Item href={userInfo ? userInfo.html_url : '#'} target="_blank" ><User size="18" />{' '}Profile</NavDropdown.Item>
                                <ViewAllRepos />
                                <ViewAllNotes />
                                {/* <NavDropdown.Item><Settings size="18" />{' '} Prefrences</NavDropdown.Item> */}
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => logOut()}><LogOut size="18" />{' '}Logout</NavDropdown.Item>
                            </NavDropdown>
                        ) : (
                            <Nav >
                                <Nav.Link className="btn btn-secondary outline" type="button" href={API_ROUTES.AUTHENTICATION}><GitHub size="18" />{' '}Login via Github</Nav.Link>
                            </Nav>
                        )
                    }
                    
                </Navbar.Collapse>
            </Navbar>
            {
                loading && <Spinner 
                    style={{
                        position: 'fixed',
                        right: '46%',
                        top: '50%',
                        fontSize: '5em',
                        zIndex: 200,
                        backgroundColor: '#339966'
                    }}
                
                    animation="grow" variant="info" />
            }
        </>
    )
}

export default Navigation;