import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { API_ROUTES, ERRORS } from '../types';

interface IAuthContext {
    isAuthenticated: Boolean,
    token: String | null,
    userInfo: any | null,
    errors: any,
    logOut: () => void
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

const AuthProvider = ({ children }: any) => {
    const [ isAuthenticated, setIsAuthenticated ] = useState(false);
    const [ token, setToken ] = useState(null);
    const [ userInfo, setUserInfo ] = useState(null);
    const [ errors, setErrors ] = useState<String | null>(null);
    

    useEffect(() => {
        // get localstorage of peruse token 
        try {
            const token = localStorage.getItem('token');

            if(!token) {
                const current_url = new URL(window.location.href);
                const q = current_url.searchParams.get("code");
                if(q) {
                    authenticateUser(q);
                } else {
                    restNoAuthState();
                }

            } else {
                requestUserInformation(token);
            }

        } catch {
            // if code is available in url then take code and try and auth user if successful put auth into localstorage
            const current_url = new URL(window.location.href);
            const q = current_url.searchParams.get("code");
            
            if(q) {
                authenticateUser(q);
            } else {
                restNoAuthState();
            }
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [])  

    
    const requestUserInformation = async (token: string) => {
       const user = await axios.get(API_ROUTES.GET_USER_INFORMATION, {
            headers: {
                'Authorization': `Bearer ${token}` 
            }
        });
        
        setUserInfo(user.data);
        setIsAuthenticated(true);
    }

    const authenticateUser = async (code: string | null) => {
        const tokenResponse = await axios.post(API_ROUTES.GET_AUTHENTICATION_TOKEN, { code });
        const { data } = tokenResponse; 
        const token = data.split('&')[0].split('=')[1];
        
        if(token !== ERRORS.BAD_VERIFICATION_CODE) {
            requestUserInformation(token);
            
            localStorage.setItem('token', token);
        } else {
            setErrors("Failed to authenticate user");
        }
    }

    const restNoAuthState = () => {
        setIsAuthenticated(false);
        setToken(null);
        setUserInfo(null);
    }

    const logOut = () => {
        localStorage.removeItem('token');
        restNoAuthState();
        window.location.reload();
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated, 
                token,
                userInfo,
                errors,
                logOut
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = () => {
    const context = useContext(AuthContext);
    if(context === undefined) {
        throw new Error("useAuth can only be used inside AuthProvider")
    }

    return context;
}

export {
    AuthProvider,
    useAuth
}