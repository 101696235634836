import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { FileText } from 'react-feather';
import Spinner from 'react-bootstrap/Spinner';
import { AppContext } from '../../../context/AppProvider';
import { useAuth } from '../../../context/AuthContext';
import {useMediaQuery } from '@react-hook/media-query';
import { API_ROUTES } from '../../../types';
import axios from 'axios';
import RichTextEditor from 'react-rte';

const AddNote = (props: any) => {
    const [ , setIsOpen ] = useState(false);
    const { 
        repo, 
        loading, 
        currentFile, 
        currentPath, 
        title,
        setTitle,
        setDescription,
        showNote,
        setShowNote,
        setLastUpdate,
        description, } = useContext(AppContext)
    

    const { isAuthenticated } = useAuth();
    const matches = useMediaQuery('only screen and (max-width: 768px)');
    const [ value, setValue ] = useState(RichTextEditor.createEmptyValue());
    
   
    useEffect(() => {
       
        if(repo) {
            const token = localStorage.getItem('token');

            axios.get(`${API_ROUTES.BACKEND_GET_NOTE}?url=${encodeURIComponent(repo.html_url)}&file=${currentPath}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(({ data }) => {
                // get first item and populate data
                if(!data.data || data.data.length < 1) {
                    setTitle('');
                    setDescription('');
                    setValue(RichTextEditor.createEmptyValue())
                    // setShowNote(false);
                } else {
                    setTitle(data.data[0].title);
                    //setDescription(data.data[0].note);
                    // try {
                    //     setDescription(RichTextEditor.createValueFromString(data.data[0].note, 'html'))
                    // } catch(error) {}
                    // setDescription(RichTextEditor.createValueFromString(data.data[0].note, 'html'))
                    setValue(RichTextEditor.createValueFromString(data.data[0].note, 'html'))
                    setLastUpdate(data.data[0].updated)
                    //setShowNote(true);
                }
                
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ currentPath, currentFile ])
    
    useEffect(() => {
        setDescription(value.toString('html'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])
    
    if(!isAuthenticated) {
        return null;
    }

    // save note
    const addNote = async (repoUrl: string, fileUrl: string) => {
        const token = localStorage.getItem('token');

        if(!token) {
            return null;
        }

        try {
            await axios.post(API_ROUTES.BACKEND_NOTE, {
                title,
                url: repoUrl,
                file: fileUrl,
                note: description
            }, {
                headers: {
                    "Content-type": "Application/json",
                    'Authorization': `Bearer ${token}`
                }
            }).then((result) => {
                console.log("Result", result)
            })
        } catch(error) {
            console.log("Errror", error)
        }
    }   
    
    const close = () => {
        setIsOpen(false);
        setShowNote(false);
    }
    
    const onChange = (value: any) => {
        setValue(value);
        setDescription(value.toString('html'))
        
        if (props.onChange) {
          props.onChange(value.toString('html'));
        }
    };

    return (
        <>
          <Modal show={showNote} onHide={() => close()}>
                <Modal.Header closeButton>
                    <Modal.Title>Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formRepo">
                            <Form.Label>Title</Form.Label>
                            <Form.Control onChange={(e: any) => setTitle(e.target.value)} value={title} type="text" placeholder="Title" />
                        </Form.Group>
                       
                        {/* <Form.Group controlId="repoDescription">
                            <Form.Label>Note</Form.Label>
                            <Form.Control  onChange={(e: any) => setDescription(e.target.value)} value={description} as="textarea" rows={3} />
                        </Form.Group> */}


                        <RichTextEditor
                            onChange={onChange} 
                            value={value}
                        />

                        
                        <br />
                        <br />
                        <Button disabled={loading} variant="primary" onClick={() => {
                            // addMyRepo && addMyRepo(title, description, repoUrl, url);
                            if(currentPath) {
                                addNote(repo.html_url, currentPath);
                            }
                            setIsOpen(false);
                            setShowNote(false);
                        }}>
                            { loading && <Spinner animation="grow" variant="light" size="sm" />}{' '} Update Note
                        </Button>
                        {' '}
                        <Button onClick={() => close()} variant="dark" >
                            Cancel
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Button 
                variant="dark" 
                className="edit-button"
                onClick={() => {
                    setIsOpen(true);
                    setShowNote(true);
                }}
            >
                <FileText />{' '}{!matches ? 'Note': null }</Button>  
            
            
        </>
    )

}


export default AddNote;